import React from 'react'
import { TopBanner } from '../components/TopBanner'
import { useState } from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import Alert from '@mui/material/Alert'
import { navigate } from 'gatsby'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardHeader from '@mui/material/CardHeader'
import DangerousIcon from '@mui/icons-material/Dangerous'

//import Grid from '@mui/system/Unstable_Grid'
import Grid from '@mui/material/Grid'
import { CardActionArea } from '@mui/material'

import Typography from '@mui/material/Typography'

import LoginIcon from '@mui/icons-material/Login'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import EuroIcon from '@mui/icons-material/Euro'

interface Props {
  Comp: React.ComponentType<{ value: string }>
}

const isBrowser = typeof window !== 'undefined'

function use() {
  // add error handling
  if (isBrowser) {
    navigate('/search')
  }
}

function create() {
  // add error handling
  if (isBrowser) {
    navigate('/create')
  }
}
function view() {
  // add error handling
  if (isBrowser) {
    navigate('/search?type=view')
  }
}

const Page = () => {
  const authToken: string =
    (isBrowser ? localStorage.getItem('myauth') : '') || ''
  //console.log('auth: ' + authUrl)
  const [errorStatus, setErrorStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  return (
    <>
      <div>
        <TopBanner user="" />
      </div>
      <Container>
        {errorStatus ? (
          <div>
            <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
              Virhetilanne: {errorMessage}
            </Alert>
          </div>
        ) : (
          ''
        )}
        <PageTitle>Valitse toiminto</PageTitle>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={5}
          columns={18}
        >
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardActionArea onClick={create}>
                <CardMedia
                  image="/fysios.png"
                  title="Fysios"
                  component="img"
                  sx={{
                    padding: 1,
                    width: 60,
                    margin: '0 auto',
                    display: 'block',
                  }}
                />
                <CardContent>
                  <Typography variant="body1" color="text.primary">
                    Luo uusi ennakkomaksu tai lahjakorttikoodi asiakkaalle
                    Fysioksen toimipisteessä
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body2" color="#007864">
                    Siirry luomaan lahjakortti/ennakkomaksu
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            sx={{
              textAlign: 'center',
            }}
          >
            <Card variant="outlined">
              <CardActionArea onClick={use}>
                <CardMedia
                  image="/hammas.png"
                  title="Hammas"
                  component="img"
                  sx={{
                    padding: 1,
                    width: 60,
                    margin: '0 auto',
                    display: 'block',
                  }}
                />
                <CardContent>
                  <Typography variant="body1" color="text.primary">
                    Käytä lahjakorttikoodi Hampaan toimipisteessä
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body2" color="#007864">
                    Siirry käyttämään lahjakorttia
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            sx={{
              textAlign: 'center',
            }}
          >
            <Card variant="outlined">
              <CardActionArea onClick={view}>
                <CardContent>
                  <br />
                  <Typography variant="body1" color="text.primary">
                    Tarkista ennakkomaksun/lahjakortin tiedot tai tulosta kortti
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body2" color="#007864">
                    Tarkista tiedot
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Page

const PageTitle = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
  font-size: 1.5rem;
  font weight: 700;
  padding: 40px;
`

const Container = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
`

const ButtonContainer = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
`
